import axiosInstance from '../services/axios';

const setup = (authStore) => {
    axiosInstance.interceptors.request.use((config) => {
        const token = authStore.token;
        if (token) {
            config.headers['Authorization'] = token;
        }

        return Promise.resolve(config);
    }, error => {
        return Promise.reject(error);
    });

    axiosInstance.interceptors.response.use(
        (res) => {
            return res;
        },
        async (err) => {
            if (err.response.status === 403) {
                authStore.token = null;
                authStore.signIn(null, null).then(() => {
                    //
                });
            }

            return Promise.reject(err);
        }
    );

};

export default setup;