import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";


const routes: Array<RouteRecordRaw> = [
    { path: '/:catchAll(.*)', redirect: '/' },
    {
        path: "/",
        name: "app",
        component: () => import("../layout/AppLayout.vue"),
        children: [
            {
                path: "integrations",
                name: "integrations",
                component: () => import("../views/pages/Integrations.vue")
            },
            {
                path: "capstime",
                name: "capstime",
                component: () => import("../views/pages/Capstime.vue")
            },
            {
                path: "pub-blacklist",
                name: "pub-blacklist",
                component: () => import("../views/pages/pubs/PubBlackList.vue")
            },
            {
                path: "force-brokers",
                name: "force-brokers",
                component: () => import("../views/pages/pubs/ForceBrokers.vue")
            },
            {
                path: "geos",
                name: "geos",
                component: () => import("../views/pages/Geo.vue")
            },
            {
                path: "bulk-resend",
                name: "bulk-resend",
                component: () => import("../views/pages/BulkResend.vue")
            },
            {
                path: "brokers",
                name: "brokers",
                component: () => import("../views/pages/Brokers.vue")
            },
            {
                path: "landing-blacklist",
                name: "landing-blacklist",
                component: () => import("../views/pages/LandingBlackList.vue")
            },
            {
                path: "pub-rotation-rules",
                name: "pub-rotation-rules",
                component: () => import("../views/pages/pubs/PubRotationRules.vue")
            },
            {
                path: "general",
                name: "general",
                component: () => import("../views/pages/GeneralStatistics.vue")
            },
            {
                path: "deposit-statistics",
                name: "deposit-statistics",
                component: () => import("../views/pages/DepositStatistics.vue")
            },
            {
                path: "set-deposit",
                name: "set-deposit",
                component: () => import("../views/pages/SetDepositPage.vue")
            },
            {
                path: "pubs",
                name: "pubs",
                component: () => import("../views/pages/pubs/Pubs.vue")
            },
            {
                path: "pub-status-resend-rules",
                name: "pub-status-resend-rules",
                component: () => import("../views/pages/pubs/PubStatusResendRules.vue")
            },
            {
                path: "country-pubs",
                name: "country-pubs",
                component: () => import("../views/pages/CountryPubs.vue")
            },
            {
                path: "convert",
                name: "convert",
                component: () => import("../views/pages/Convert.vue")
            },
            {
                path: "pub-mail-delivery",
                name: "pub-mail-delivery",
                component: () => import("../views/pages/pubs/PubMailDelivery.vue")
            },
            {
                path: "query-logs",
                name: "query-logs",
                component: () => import("../views/pages/QueryLogs.vue")
            }
        ]
    }
];

/*




 */

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;
