import { createApp } from "vue";
import axios from 'axios'
import VueAxios from 'vue-axios'

import App from './App.vue'
import router from './router'

import {createPinia} from "pinia";
import piniaPluginPersistedState from 'pinia-plugin-persistedstate'

import './assets/theme/theme.css';

import 'vue-material-design-icons/styles.css'
import 'vue3-json-viewer/dist/index.css';

import JsonViewer from "vue3-json-viewer";


import PrimeVue from 'primevue/config';
import DataTable from "primevue/datatable";
import Column from 'primevue/column';
import Row from 'primevue/row';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import InputText from "primevue/inputtext";
import Dialog from 'primevue/dialog';
import Password from "primevue/password";
import Calendar from "primevue/calendar";
import InputSwitch from "primevue/inputswitch";
import MultiSelect from "primevue/multiselect";
import InputNumber from 'primevue/inputnumber';
import ProgressSpinner from 'primevue/progressspinner';
import Checkbox from 'primevue/checkbox';
import SelectButton from 'primevue/selectbutton';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';


import setupInterceptors from './interceptors/axios.interceptors';
import createAuthStore from "@/store/auth.store";

const pinia = createPinia();
const app = createApp(App);

app.use(PrimeVue, { ripple: true });
app.component('DataTable', DataTable);
app.component('Column', Column);
app.component('Row', Row);
app.component('Button', Button);
app.component('Dropdown', Dropdown);
app.component('InputText', InputText);
app.component('Dialog', Dialog)
app.component('Password', Password)
app.component('Calendar', Calendar);
app.component('InputSwitch', InputSwitch);
app.component('MultiSelect', MultiSelect);
app.component('InputNumber', InputNumber);
app.component('ProgressSpinner', ProgressSpinner);
app.component('Checkbox', Checkbox);
app.component('SelectButton', SelectButton);
app.component('Toast', Toast);

app.use(ToastService);

app.use(pinia);

app.use(JsonViewer);

pinia.use(piniaPluginPersistedState);

setupInterceptors(createAuthStore());

app.use(VueAxios, axios);
app.use(router);

app.mount('#app')

