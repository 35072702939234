import { defineStore } from 'pinia';
import { IAuthState, IPubMailDelivery } from '@/interfaces';
import axiosInstance from '@/services/axios';

const authStore = defineStore('auth', {
    state: (): IAuthState => ({
        token: '',
        currentRoute: '/authStore'
    }),
    getters: {
        isAuthorized(): boolean {
            return this.token !== null && this.token !== undefined && this.token.length > 0;
        }
    },
    actions: {
        signInViaOlimob() {
            window.location.href = process.env.NODE_ENV === 'development'
                ? 'https://olimob.com/oauth?client_id=crypto&redirect=http://admin.api-3580-per-day.com:8080'
                : 'https://olimob.com/oauth?client_id=crypto&redirect=http://admin.api-3580-per-day.com';
        },
        async signIn(authorization_code: string, sign: string): Promise<boolean> {
            try {
                const result = await axiosInstance.get(`admin/sign-in?authorization_code=${authorization_code}&sign=${sign}`);
                if (result && result.data) {
                    const bearer = result.data['Bearer'];
                    if (bearer) {
                        this.token = bearer;

                        return true;
                    }
                }
            } catch (error) {
                console.error(error);
            }

            return false;
        },
        logout() {
            this.token = null;
        }
    },
    persist: true
});

const createAuthStore = () => authStore();

export default createAuthStore;
